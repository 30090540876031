import { createContext } from 'react';
import { Route, generatePath, useRouteMatch, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import VerticalTab from '@ui/molecules/VerticalTab';
import Profile from '../../unorganized/Profile';
import Subscription from '../../unorganized/Subscription';
import EmailNotification from '../../unorganized/EmailNotification';
import TemplateTabsFixed from '@ui/Templates/TemplateTabsFixed';
import RouteTransition from '@ui/organisms/RouteTransition';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import styled from 'styled-components';
import ChannelGuestsPage from './ChannelGuestsPage';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import PublicApiPage from './PublicApiPage';
import DeleteAccount from '@/features/DeleteAccount';
import MainLayout from '../layout/Main/Main.layout';
import Topbar from '@/components/unorganized/Topbar';
import NavigationMenu from '@/components/NavigationMenu';
import Footer from '@/components/Footer';

export const RouteUserPageContext = createContext(null);

const TAB_TITLES_USERS = {
    profile: <FormattedMessage defaultMessage="Profil" />,
    billing: <FormattedMessage defaultMessage="Facturation" />,
    subscription: <FormattedMessage defaultMessage="Abonnement" />,
    users: <FormattedMessage defaultMessage="Utilisateurs" />,
    email: <FormattedMessage defaultMessage="Notifications" />,
    'public-api': <FormattedMessage defaultMessage="API Publique" />,
    delete: <FormattedMessage defaultMessage="Suppression du compte" />,
    mobile: <FormattedMessage defaultMessage="Compte" />,
};

function UserPage() {
    const SLIDE = 20;
    const { path } = useRouteMatch();

    const { data: user } = useUserQuery();
    const userHasHostingAccess = user?.canHostOnAusha === true;
    const hasAccessToUsersManagement =
        user?.options?.includes(USER_OPTIONS.MULTI_USERS_MANAGEMENT) && !user?.guest;

    return (
        <MainLayout
            header={
                <>
                    <Topbar />
                    <NavigationMenu />
                </>
            }
            footer={<Footer />}
        >
            <RouteUserPageContext.Provider value={TAB_TITLES_USERS}>
                <UserPageContentWrapper>
                    <Route
                        strict
                        path={[`${path}:showId/:tab`, `${path}:tab`]}
                        /* eslint-disable-next-line react/no-children-prop */
                        children={({ match }) => {
                            const { showId } = match.params;
                            const pathTab =
                                showId && showId.match(/\d+/g)
                                    ? generatePath(`${path}:showId/`, { showId })
                                    : path;

                            return (
                                <TemplateTabsFixed
                                    sectionTitle={<FormattedMessage defaultMessage="Compte" />}
                                    routeTabs={showId ? 'user.edit.show.tab' : 'user.edit.tab'}
                                    tabsTitle={TAB_TITLES_USERS}
                                    tabs={
                                        <>
                                            <VerticalTab tab="profile" />
                                            <VerticalTab tab="subscription" />
                                            {hasAccessToUsersManagement && (
                                                <VerticalTab tab="users" />
                                            )}
                                            {userHasHostingAccess && (
                                                <>
                                                    <VerticalTab tab="email" />
                                                    <VerticalTab tab="public-api" />
                                                </>
                                            )}
                                            <VerticalTab tab="delete" />
                                        </>
                                    }
                                >
                                    <RouteTransition path={`${pathTab}profile`} slide={SLIDE}>
                                        <Profile />
                                    </RouteTransition>
                                    <RouteTransition
                                        path={[`${path}billing`, `${path}:showId/billing`]}
                                        slide={SLIDE}
                                    >
                                        <Redirect to={`${path}subscription`} />
                                    </RouteTransition>
                                    {hasAccessToUsersManagement && (
                                        <RouteTransition path={`${path}users`} slide={SLIDE}>
                                            <ChannelGuestsPage />
                                        </RouteTransition>
                                    )}
                                    <RouteTransition
                                        strict
                                        path={[
                                            `${path}subscription`,
                                            `${path}:showId/subscription`,
                                        ]}
                                        slide={SLIDE}
                                    >
                                        <Subscription />
                                    </RouteTransition>
                                    {userHasHostingAccess && (
                                        <>
                                            <RouteTransition path={`${pathTab}email`} slide={SLIDE}>
                                                <EmailNotification />
                                            </RouteTransition>
                                            <RouteTransition
                                                path={`${pathTab}public-api`}
                                                slide={SLIDE}
                                            >
                                                <PublicApiPage />
                                            </RouteTransition>
                                        </>
                                    )}
                                    <RouteTransition path={`${pathTab}delete`} slide={SLIDE}>
                                        <DeleteAccount />
                                    </RouteTransition>
                                </TemplateTabsFixed>
                            );
                        }}
                    />
                </UserPageContentWrapper>
            </RouteUserPageContext.Provider>
        </MainLayout>
    );
}

const UserPageContentWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    padding-top: 4rem;
    padding-inline: 1.5rem;
    width: 100%;
    max-width: var(--small-container-width);
    margin-inline: auto;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding-inline: 0.75rem;
    }
`;

export default UserPage;
