import { lazy, Suspense } from 'react';
import { PoseGroup } from 'react-pose';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useWindowDimensions } from '@/shared/hooks/useWindowDimensions';
import { AuthRoute, GuestRoute } from './legacy/authorisationRoutes';
import DefaultRoute from './legacy/DefaultRoute';
import FetcherUser from './unorganized/FetcherUser';
import Loader from './ui/atoms/Loader';
import LoginWithProviderPage from './app/pages/LoginWithProviderPage';
import LoginWithSharingProviderPage from './app/pages/LoginWithSharingProviderPage';
import DiscordPage from './app/pages/DiscordPage';
import Unsubscribe from './app/pages/Unsubscribe';
import Subscribe from './app/pages/Subscribe';
import OffersPage from './app/pages/OffersPage';
import AushaClubCallback from './app/pages/AushaClubCallback';
import EmailConfirmationPage from './app/pages/EmailConfirmationPage';
import StripeCheckoutSuccessPage from './app/pages/StripeCheckoutSuccessPage';
import Topbar from './unorganized/Topbar';
import FullPageLayout from './app/layout/Fullpage.layout';
import SponsorshipPage from './app/pages/SponsorshipPage';
import { OverlayProvider } from 'react-aria';
import PricingSuccessPage from '@app/pages/PricingSuccessPage';
import { StatsDateSelectorProvider } from '@/context/StatsDateSelectorContext';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { AudioPlayerContextProvider } from '@/context/AudioPlayerContext';
import ApplicationPage from './app/pages/ApplicationPage';

const LoginPage = lazy(() => import('./app/pages/LoginPage'));
const NewShowPage = lazy(() => import('./app/pages/NewShowPage'));
const SongLibraryPage = lazy(() => import('./app/pages/SongLibraryPage'));
const ChannelPage = lazy(() => import('./app/pages/ChannelPage'));

import Responsive from '@/context/responsive.context';
import useAmplitudeLogLocationChangeEvent from '@/shared/hooks/useAmplitudeLogLocationChangeEvent.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import ErrorPage from './app/pages/ErrorPage';
import MaintenancePage from './app/pages/MaintenancePage';
import useUserHasHostingAccess from '@/shared/hooks/useUserHasHostingAccess.hook';
import UserPage from './app/pages/UserPage';
import AppMiddleware from '@/shared/components/AppMiddleware';

require('dayjs/locale/fr');

function App() {
    const location = useLocation();
    const isLoginAsMode = location.pathname === '/loginas';

    useAmplitudeLogLocationChangeEvent();
    const { media, width } = useWindowDimensions();

    const { data: user } = useUserQuery({ enabled: !isLoginAsMode });
    const userHasHostingAccess = useUserHasHostingAccess({ enabled: !isLoginAsMode });
    const hasAccessToChannelPage = user?.options.includes(USER_OPTIONS.CHANNEL_PAGE);

    return (
        <Responsive.Provider value={{ ...media, width }}>
            <OverlayProvider style={{ height: '100%' }}>
                <AudioPlayerContextProvider>
                    <StatsDateSelectorProvider>
                        <Switch>
                            <Route path="/error" exact component={ErrorPage} />
                            <Route path="/error/maintenance" component={MaintenancePage} />

                            <Route path="/ausha-club-callback">
                                <AushaClubCallback />
                            </Route>
                            <Route
                                render={() => (
                                    <FetcherUser
                                        fallback={(isLoading) => (
                                            <PoseGroup animateOnMount>
                                                {isLoading && <Loader key={0} />}
                                            </PoseGroup>
                                        )}
                                    >
                                        <Suspense fallback={null}>
                                            <Switch>
                                                <AuthRoute
                                                    path="/checkout-success"
                                                    component={StripeCheckoutSuccessPage}
                                                />
                                                <AuthRoute
                                                    path="/app/subscription/offers"
                                                    component={OffersPage}
                                                />
                                                <AuthRoute
                                                    path="/email-confirmation"
                                                    component={EmailConfirmationPage}
                                                />
                                                <AuthRoute
                                                    path="/discord"
                                                    component={DiscordPage}
                                                />
                                                <Route path="/app/social/:provider">
                                                    <LoginWithSharingProviderPage />
                                                </Route>
                                                {userHasHostingAccess && (
                                                    <AuthRoute path="/app/sponsorship">
                                                        <FullPageLayout header={<Topbar />}>
                                                            <SponsorshipPage />
                                                        </FullPageLayout>
                                                    </AuthRoute>
                                                )}
                                                <AuthRoute path="/app/show/new">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <NewShowPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute
                                                    path="/app/show/:showId?/:tabMenu?"
                                                    component={ApplicationPage}
                                                />
                                                {userHasHostingAccess && (
                                                    <AuthRoute path="/app/song-library">
                                                        <FullPageLayout header={<Topbar />}>
                                                            <SongLibraryPage />
                                                        </FullPageLayout>
                                                    </AuthRoute>
                                                )}
                                                {userHasHostingAccess && hasAccessToChannelPage && (
                                                    <AuthRoute path="/app/channel/settings">
                                                        <FullPageLayout header={<Topbar />}>
                                                            <ChannelPage />
                                                        </FullPageLayout>
                                                    </AuthRoute>
                                                )}
                                                <AuthRoute
                                                    path="/app/pricing/success"
                                                    component={PricingSuccessPage}
                                                />
                                                <AuthRoute
                                                    strict
                                                    path="/app/user/"
                                                    component={UserPage}
                                                />
                                                <AuthRoute path="/app">
                                                    <AppMiddleware />
                                                </AuthRoute>
                                                <GuestRoute
                                                    path="/login/:provider(google|facebook)"
                                                    component={LoginWithProviderPage}
                                                />
                                                <Route
                                                    path="/(login|password)"
                                                    component={LoginPage}
                                                />
                                                <Route
                                                    path="/unsubscribe"
                                                    component={Unsubscribe}
                                                />
                                                <Route path="/subscribe" component={Subscribe} />
                                                <DefaultRoute to="/app" />
                                            </Switch>
                                        </Suspense>
                                    </FetcherUser>
                                )}
                            />
                        </Switch>
                    </StatsDateSelectorProvider>
                </AudioPlayerContextProvider>
            </OverlayProvider>
        </Responsive.Provider>
    );
}

export default App;
