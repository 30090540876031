import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import usePasswordFormSchema, {
    DeleteAccountPasswordFormSchema,
} from './usePasswordFormSchema.hook';
import Text from '@ui/atoms/Text';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useAuthStore from '@/shared/hooks/useAuthStore';
import { useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Button from '@/components/Button';
import { AxiosError } from 'axios';
import deleteAccountMutation from '@/queries/user/deleteAccountMutation';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface PasswordFormProps {
    onAfterSubmit: () => void;
}

const PasswordForm = ({ onAfterSubmit }: PasswordFormProps) => {
    const intl = useIntl();
    const { data: user } = useUserQuery();
    const deleteAccount = deleteAccountMutation();
    const schema = usePasswordFormSchema(user?.external);
    const { control, handleSubmit, formState, setError } = useForm<DeleteAccountPasswordFormSchema>(
        {
            mode: 'onChange',
            resolver: zodResolver(schema),
        },
    );
    const toast = useModalToastQueue();
    const { logout } = useAuthStore();
    const queryClient = useQueryClient();
    const { isValid, isDirty } = formState;

    if (!user) return null;

    const onSubmit = (formData: DeleteAccountPasswordFormSchema) => {
        deleteAccount.mutate(formData, {
            onSuccess: async () => {
                sendAmplitudeLogEvent('Account deleted');
                toast.success(
                    <FormattedMessage defaultMessage="Votre compte a bien été supprimé." />,
                );
                await logout(queryClient);
            },
            onError: (error: unknown) => {
                const err = error as AxiosError;
                if (err?.response?.status === 422) {
                    setError('password', {
                        type: '401',
                        message: intl.formatMessage({
                            defaultMessage: "Votre mot de passe n'est pas valide",
                        }),
                    });
                    toast.alert(
                        <FormattedMessage defaultMessage="Les modifications n’ont pas pu être enregistrées. Veuillez réessayer." />,
                    );
                }
            },
        });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {user.external ? (
                <Text>
                    <FormattedMessage defaultMessage="Souhaitez-vous supprimer votre compte Ausha ? 💔" />
                </Text>
            ) : (
                <InputTextController
                    control={control}
                    name="password"
                    type="password"
                    label={<FormattedMessage defaultMessage="Mot de passe" />}
                    placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                    isRequired
                />
            )}
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={deleteAccount.isLoading}
                    isDisabled={!isValid || !isDirty}
                >
                    <FormattedMessage defaultMessage="Supprimer mon compte" />
                </Button>
                <CancelButton variant="ghost" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;
`;
const CancelButton = styled(Button)`
    color: var(--neutral500);
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: auto;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

export default observer(PasswordForm);
