import {
    KeywordRanking,
    KeywordsRanking,
} from '@queries/psoKeywordsRanking/usePsoKeywordsRankingQuery.hook';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortDirection,
    useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import KeywordCell from './KeywordCell';
import RankCell from './RankCell';
import SearchVolumeCell from './SearchVolumeCell';
import DifficultyScoreCell from './DifficultyScoreCell';
import FollowCell from '@app/pages/PsoPage/PsoKeywordsRanking/Results/Datatable/FollowCell';
import LiveSearchLinkCell from './LiveSearchLinkCell';
import { useParams } from 'react-router';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import useFollowKeywordMutation from '@queries/psoChecker/useFollowKeywordMutation.hook';
import useKeywordQuotaQuery from '@queries/pso/useKeywordQuotaQuery.hook';
import type { Platform } from '@/api/pso/types';
import styled, { css } from 'styled-components';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useQuery from '@/shared/hooks/useQuery';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';

interface KeywordsRankingResult {
    keyword: string;
    ranking: number;
    searchVolume: number;
    difficultyScore: number;
    follow: boolean;
    liveSearchLink: string;
}

interface DatatableProps {
    rows: KeywordsRanking;
}

const Datatable = ({ rows }: DatatableProps) => {
    const [sorting, setSorting] = useState([
        { id: 'ranking', desc: false },
        { id: 'keyword', desc: false },
    ]);

    const { showId } = useParams<{ showId: string }>();
    const { data: psoConfiguration } = usePsoQuery({ showId, enabled: !!showId });
    const { data: keywordQuota } = useKeywordQuotaQuery({ showId });
    const followKeyword = useFollowKeywordMutation();
    const toast = useModalToastQueue();

    const query = useQuery();
    const platform: Platform = query.get('platform') as Platform;
    const store: string = query.get('store') || '';

    const handleFollowKeyword = (keyword: string) => {
        if (keywordQuota.reached) {
            toast.alert(
                <FormattedMessage defaultMessage="Vous avez atteint la limite de votre quota de mots-clés. Pour en ajouter d’avantage, libérez de l’espace depuis votre PSO Control Panel." />,
            );
            return;
        }
        followKeyword.mutate(
            { showId, keyword },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage
                            defaultMessage={`Le mot-clé "{keyword}" a bien été ajouté à votre liste de mots-clés du PSO Control Panel. 👌`}
                            values={{
                                keyword,
                            }}
                        />,
                    );
                },
            },
        );
    };

    const headerTooltipContentLookup = {
        keyword: <FormattedMessage defaultMessage="Liste de vos mots-clés." />,
        ranking: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés ce jour." />
        ),
        searchVolume: (
            <FormattedMessage defaultMessage="Le volume de recherche est un indicateur compris entre 5 et 100 qui montre la popularité d'un mot-clé en termes de recherches. Plus le nombre est élevé, plus le mot-clé est populaire." />
        ),
        difficultyScore: (
            <FormattedMessage defaultMessage="La difficulté est un indicateur entre 0 et 100 qui indique le niveau de concurrence d'un mot-clé. Plus le chiffre est élevé, plus la concurrence est forte." />
        ),
        follow: (
            <FormattedMessage defaultMessage="Ajoutez les mots-clés à la liste de mots-clés suivis de votre PSO Control Panel." />
        ),
        liveSearchLink: (
            <FormattedMessage defaultMessage="Analysez plus de données de ce mot-clé via notre outil de recherches en temps réel, la Live Search." />
        ),
    };

    const columnHelper = createColumnHelper<KeywordsRankingResult>();
    const columns = [
        columnHelper.accessor('keyword', {
            cell: (info) => {
                return <KeywordCell keyword={info.getValue()} />;
            },
            header: () => <FormattedMessage defaultMessage="Mot-clé" />,
            size: 368,
        }),
        columnHelper.accessor('ranking', {
            cell: (info) => <RankCell ranking={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Classement" />,
            size: 138,
        }),
        columnHelper.accessor('searchVolume', {
            cell: (info) => <SearchVolumeCell score={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Vol. de Recherche" />,
            size: 138,
        }),
        columnHelper.accessor('difficultyScore', {
            cell: (info) => <DifficultyScoreCell score={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Difficulté" />,
            size: 138,
        }),
        columnHelper.accessor('follow', {
            cell: (info) => (
                <FollowCell
                    follow={info.getValue()}
                    onFollow={() => handleFollowKeyword(info.row.original.keyword.toLowerCase())}
                />
            ),
            header: () => <FormattedMessage defaultMessage="Suivre" />,
            enableSorting: false,
            size: 42,
        }),
        columnHelper.accessor('liveSearchLink', {
            cell: (info) => <LiveSearchLinkCell to={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Live Search" />,
            enableSorting: false,
            size: 73,
        }),
    ];

    const table = useReactTable({
        data: rows.map((row: KeywordRanking) => ({
            keyword: row.keyword,
            ranking: row.ranking,
            searchVolume: row.searchVolume,
            difficultyScore: row.difficultyScore,
            follow: psoConfiguration.keywords.includes(row.keyword.toLowerCase()) || false,
            liveSearchLink: `/app/show/${showId}/pso/livesearch?keyword=${row.keyword.toLowerCase()}&platform=${platform}&store=${store}`,
        })),
        columns,
        state: {
            sorting,
        },
        enableSortingRemoval: false,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });

    const sortIconRender = (isSorted: false | SortDirection) => {
        return isSorted === 'asc' ? (
            <SortIcon icon={icon({ name: 'arrow-up', style: 'solid' })} size="lg" />
        ) : (
            <SortIcon icon={icon({ name: 'arrow-down', style: 'solid' })} size="lg" />
        );
    };

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) =>
                            header.isPlaceholder ? null : (
                                <Th
                                    key={header.id}
                                    sortable={header.column.getCanSort()}
                                    isSorted={header.column.getIsSorted() !== false}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <TooltipTrigger delay={0} closeDelay={0}>
                                        <TableHeaderTooltipTriggerWrapper>
                                            <ThContent
                                                sortable={header.column.getCanSort()}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}

                                                {sortIconRender(header.column.getIsSorted())}
                                            </ThContent>
                                        </TableHeaderTooltipTriggerWrapper>
                                        <Tooltip placement="top">
                                            {headerTooltipContentLookup[
                                                header.id as keyof typeof headerTooltipContentLookup
                                            ] || null}
                                        </Tooltip>
                                    </TooltipTrigger>
                                </Th>
                            ),
                        )}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td
                                key={cell.id}
                                style={{
                                    width: `${cell.column.getSize()}px`,
                                    maxWidth: '100%',
                                }}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
`;
const Thead = styled.thead``;
const Td = styled.td`
    width: 10px;
    min-width: auto;
    padding: 1rem;
`;
const Tr = styled.tr`
    height: 3.5rem;
    & > ${Td}:not(: last-of-type) {
        border-right: 1px solid var(--neutral100);
    }
`;
const Th = styled.th<{ sortable: boolean; isSorted: boolean }>`
    background-color: var(--neutral50);
    padding: 1rem;
    text-align: left;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `};

    svg {
        visibility: ${(props) => (props.isSorted ? 'visible' : 'hidden')};
    }

    &:hover svg {
        visibility: ${(props) => (props.sortable ? 'visible' : 'hidden')};
    }

    @media (min-width: 768px) {
        white-space: nowrap;
    }

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const ThContent = styled.div<{ sortable: boolean }>`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: min-content;
    align-self: stretch;
    font-weight: var(--fw-semibold);

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const TableHeaderTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    color: var(--neutral500);
`;
const Tbody = styled.tbody`
    & > ${Tr}:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const SortIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 0.75rem;
`;

export default Datatable;
